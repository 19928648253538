<template>
  <div id="error">
    404页面
  </div>
</template>

<script>

export default {
  name: 'errpr',
}
</script>

<style>

</style>
